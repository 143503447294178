<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Rutero GPV</v-card-title>

      <v-card-text class="text--primary">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-menu
                ref="startDate_menu"
                v-model="select_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterMainCredential.date"
                    v-bind:label="$t('Date')"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterMainCredential.date"
                  no-title
                  @input="select_date_menu = false"
                  :first-day-of-week="1"
                  :locale="this.$i18n.locale"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-autocomplete
                v-model="filterMainCredential.idGPV"
                v-bind:label="`GPV`"
                v-bind:placeholder="$t('start_typing_to_search')"
                item-text="fullname"
                item-value="id"
                :search-input.sync="search_gpvs"
                :loading="isSearching['gpvs']"
                :items="filtered_gpvs"
                hide-no-data
                hide-selected
                filled
                clearable
                required
                :rules="requiredRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-autocomplete
                v-model="filterMainCredential.companyCodes"
                :label="$t('Company')"
                v-bind:placeholder="$t('start_typing_to_search')"
                item-text="name"
                item-value="id"
                :search-input.sync="search_companies"
                :loading="isSearching['companies']"
                :items="filtered_companies"
                hide-no-data
                filled
                multiple
                clearable
                required
                :rules="requiredRulesArray"
                @change="onChangeCompany"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
              class="d-flex justify-content-center"
            >
              <v-checkbox
                v-model="isOnlyActiveGPVs"
                label="Solo GPV's activos"
                hide-details="auto"
                @change="onChangeIsOnlyActiveGPVs"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="1" class="text-center">
              <v-btn color="blue darken-1" @click="onClickFilter">
                {{ $t("go") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <p class="trencadis-title mb-0" v-if="this.mainRoute">
          Ruta <b> : {{ this.mainRoute?.name }}</b>
        </p>
        <v-row class="mb-2">
          <v-col cols="12" sm="12" md="12">
            <b-alert show variant="info" v-if="isLoadingData">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <b-alert
              v-else-if="
                !isLoadingData &&
                  (!worksessionId ||
                    !filteredPosList ||
                    filteredPosList.length === 0)
              "
              variant="warning"
              class="m-0 mt-4 p-2 my-alert"
              show
            >
              <i> No hay datos....</i>
            </b-alert>
            <v-row
              v-else-if="
                !isLoadingData && filteredPosList && filteredPosList.length > 0
              "
            >
              <v-col cols="12" sm="12" md="12">
                <routePosList
                  :filteredPosList="filteredPosList"
                  :visitTypes="visitTypes"
                  :onClickRemoveAdditionalItem="onClickRemoveAdditionalItem"
                ></routePosList>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import routePosList from "@/views/session/routePosList.vue";

export default {
  name: "RouteGPV",
  components: {
    routePosList
  },
  data: function() {
    return {
      filter_main_credentials_fetch_url:
        "reporting/routegpv/getfiltermaincredentials",
      filterMainCredential: {
        companyCodes: [],
        date: null,
        idGPV: null
      },
      // filterMainCredential: {
      //   companyCodes: [],
      //   date: "2024-03-01",
      //   idGPV: 13
      // },

      user_route: null,
      isLoadingData: false,
      worksessionId: null,
      session: null,
      meta: null,
      filteredPosList: null,
      visitTypes: [],
      isOnlyActiveGPVs: true,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      search_gpvs: null,
      filtered_gpvs: [],
      search_companies: null,
      filtered_companies: [],
      isSearching: {},
      select_date_menu: false
    };
  },
  watch: {
    async search_companies(val) {
      this.searchValues("companies", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    }
  },
  computed: {
    mainRoute() {
      let ret = null;
      if (this.user_route) {
        if (Array.isArray(this.user_route) && this.user_route.length > 0) {
          ret = this.user_route[0];
        }
      }
      return ret;
    },
    isFutureDate() {
      let ret = false;
      const currentDate = moment().format("YYYY-MM-DD");
      if (currentDate < this.filterMainCredential.date) {
        ret = true;
      }
      return ret;
    }
  },
  methods: {
    onChangeIsOnlyActiveGPVs() {
      this.searchValues("gpvs", "");
    },
    onChangeCompany() {
      this.searchValues("gpvs", "");
    },
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = {
        ...this.filterMainCredential,
        isOnlyActiveGPVs: this.isOnlyActiveGPVs
      };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "companies") {
        this.filterMainCredential.idGPV = null;
        this.filtered_companies = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    async onClickFilter() {
      if (this.$refs.form.validate()) {
        this.initDay();
      }
    },
    async onClickRemoveAdditionalItem(additionalIds) {
      console.log(additionalIds);
      if (additionalIds.length > 0 && confirm(this.$t("confirmdelete"))) {
        let fetch_url = "worksession/removeadditionalitems";
        let formdata = {
          additionalIds
        };
        let response = await ApiService.post(fetch_url, formdata);
        if (response.success) {
          this.getRouteDetails(this.worksessionId);
        }
      }
    },
    async getRouteDetails(worksessionId) {
      this.isLoadingData = true;
      let fetch_url = "reporting/routegpv/getRouteDetails";
      let form_data = { worksessionId };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        if (resp?.ret) {
          const { data, meta, visitTypes, user_route } = resp.ret;
          this.user_route = user_route;
          this.visitTypes = visitTypes;
          this.session = data;
          this.meta = meta;
          const { routePos, additionalPOSdata, mergedPosData } = this.meta;
          this.filteredPosList = mergedPosData
            .map(el => {
              let item = { ...el };
              const { isVisited, statusValue } = item.status;
              if (item.status.scheduleDateTime) {
                item.status.originalScheduleDateTime = moment(
                  item.status.originalScheduleDateTime
                )
                  .local()
                  .format("DD/MM/YYYY HH:mm");
              }
              return item;
            })
            .filter(el => el);
        }
        this.isLoadingData = false;
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    async initDay() {
      // initday
      this.isLoadingData = true;
      let fetch_url = "reporting/routegpv/initday";
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        if (resp?.worksessionId) {
          this.worksessionId = resp.worksessionId;
          this.getRouteDetails(this.worksessionId);
        }
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    async init() {
      this.searchValues("gpvs", "");
      this.searchValues("companies", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
